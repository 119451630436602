import { render, staticRenderFns } from "./Caimed.vue?vue&type=template&id=3ddf0f16&scoped=true&"
import script from "./Caimed.vue?vue&type=script&lang=js&"
export * from "./Caimed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ddf0f16",
  null
  
)

export default component.exports