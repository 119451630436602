<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('project.edit','报修创建'):$l('project.add','报修创建')"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('project.labelWidth','80px')">
      <el-tag class="tag">报修信息</el-tag>
      <div class="vm-separate content">
        <el-form-item :label="$l('project.name','报修人')" prop="reporterName">
          <el-input v-model="model.reporterName"></el-input>
        </el-form-item>
        <el-form-item :label="$l('project.name','报修电话')" prop="reporterPhone">
          <el-input v-model="model.reporterPhone"></el-input>
        </el-form-item>
      </div>

      <div class="vm-separate content">
        <el-form-item :label="$l('project.name','报修时间')" prop="reportedTime" :rules="$rule.notNull">
          <el-date-picker v-model.trim="model.reportedTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" clearable></el-date-picker>
        </el-form-item>
        <div></div>
      </div>

      <div class="vm-separate content">
        <el-form-item label="故障描述" prop="faultPhenomenon">
          <el-select
            v-model="model.faultPhenomenon"

            filterable
            allow-create
            default-first-option
            placeholder="">
            <el-option
              v-for="(item,index) in phenomenon"
              :key="index"
              :lable="item.name"
              :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="vm-separate content">
        <el-form-item :label="$l('project.reporterName','是否滑梯坠梯')" prop="elevatorFalling">
          <el-select v-model="model.elevatorFalling">
            <el-option :value="true" label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$l('project.reporterPhone','是否停梯')" prop="elevatorStopped">
          <el-select v-model="model.elevatorStopped">
            <el-option :value="true" label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$l('project.reporterPhone','是否困人')" prop="peopleTrapped">
          <el-select v-model="model.peopleTrapped">
            <el-option :value="true" label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="vm-separate content">
        <el-form-item label="备注" prop="reportRemark">
          <el-input v-model="model.reportRemark"></el-input>
        </el-form-item>
      </div>
      <el-tag class="tag">电梯信息</el-tag>
      <div class="vm-separate content">
        <el-form-item label="楼盘名称" prop="realEstateName" :rules="$rule.notNull">
          <vm-select-input :value="model.realEstateName" @select="$refs.realEstateSelect.open()"></vm-select-input>
        </el-form-item>
        <el-form-item label="电梯" prop="elevatorName">
          <vm-select-input :value="model.elevatorId" @select="$refs.elevatorSelect.open()"></vm-select-input>
        </el-form-item>
      </div>
      <el-table
        :data="elevatorList"
        label-position="left"
        class="demo-table-expand"
        style="margin-bottom: 4px"
      >
        <el-table-column prop="realEstateName" label="楼盘名称" align="center"></el-table-column>
        <el-table-column prop="buildingName" label="楼宇名称" align="center"></el-table-column>
        <el-table-column prop="elevatorName" label="电梯名称" align="center"></el-table-column>
        <el-table-column prop="regCode" label="注册代码" align="center"></el-table-column>
        <el-table-column prop="orderCode" label="工单编号" align="center"></el-table-column>
        <el-table-column prop="faultPhenomenon" label="故障现象" align="center"></el-table-column>
        <el-table-column prop="orderStatusDesc" label="工单状态" align="center"></el-table-column>
        <el-table-column prop="createTime" label="生成时间" align="center"></el-table-column>
      </el-table>
      <!--      <div class="vm-separate content">-->
      <!--        <div>项目名称:{{elevator.maintain.maintenanceProjectName}}</div>-->
      <!--        <div>开始时间:{{elevator.maintain.startTime}}</div>-->
      <!--        <div>结束时间:{{elevator.maintain.endTime}}</div>-->
      <!--      </div>-->
      <!--      <div class="vm-separate content">-->
      <!--        <div>使用单位:{{elevator.maintain.usingCompanyName}}</div>-->
      <!--        <div>客户姓名:{{elevator.maintain.usingCompanyContactName}}</div>-->
      <!--        <div>客户电话:{{elevator.maintain.usingCompanyContactPhone}}</div>-->
      <!--      </div>-->
      <!--      <div class="vm-separate content">-->
      <!--        <div>维保班组:{{elevator.maintain.teamName}}</div>-->
      <!--        <div>队长姓名:{{elevator.maintain.teamLeaderName}}</div>-->
      <!--        <div>队长电话:{{elevator.maintain.teamLeaderPhone}}</div>-->
      <!--      </div>-->
    </el-form>

    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <user-select ref="userSelectWb" @select="selectUserWb($event)"></user-select>
    <customer-select ref="customerSelect" @select="selectCustomer($event)"></customer-select>
    <real-estate-select ref="realEstateSelect" @select="realEstateSelect"></real-estate-select>
    <elevator-select ref="elevatorSelect" @select="realEstateSelect"></elevator-select>
    <project-select ref="projectSelect" @select="projectSelect"></project-select>
  </el-dialog>
</template>
<script>
  import  UserSelect from "@/views/user/UserSelect";
  import CustomerSelect from "@/views/customer/CustomerSelect";
  import ProjectSelect from "@/views/project/ProjectSelect";
  import RealEstateSelect from "@/views/elevatorUsingRealEstate/RealEstateSelect";
  import ElevatorSelect from "@/views/elevator/ElevatorSelect.vue";
  import moment from "moment";
  // import moment from "moment";
  export default {
    components: {UserSelect, CustomerSelect,ProjectSelect,RealEstateSelect,ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        elevator:{location:{},brief:{},maintain:{}},
        model: {

          id: 0,
          name: "",
          customerId: "",
          customerName: "",
          wbManagerId: "",
          wbManagerName: "",
          status: "",
          tasks:[],
          tasksMapping:[],
          reportedTime:moment().format("YYYY-MM-DD HH:mm:ss"),
        },

        phenomenon:[],
        elevatorList:[],
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
        this.getPhenomenon();
      },
      getPhenomenon() {
        this.$http.get("basic/elevator-fault-phenomenon-dict-list").then(res=>{

          this.phenomenon=res;
        });
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/repair-report/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            if (!this.model.reportedTime) {
              this.model.reportedTime = moment();
            }
            console.log(this.model,186);
          });
        }
      },
      selectCustomer(row) {
        this.model.customerId = row.id;
        this.model.customerName = row.name;
      },
      selectUserWb(row) {
        this.model.wbManagerId = row.id;
        this.model.wbManagerName = row.name;
      },

      realEstateSelect(row) {
        this.$set(this.model,"realEstateId",row.id);

        this.$set(this.model,"realEstateName",row.name);
        const createTimeFrom = moment(new Date().getTime()-1000*60*30).format("YYYY-MM-DD HH:mm:ss");
        this.$http.get("/maintenance/repair-report/page",{realEstateId:row.id,createTimeFrom,orderColumnName:"createTime",maintenanceType:"as_need"}).then(res=>{
          this.elevatorList = res.records;
        });

      },
      projectSelect(row) {

        this.$set(this.model,"projectId",row.id);
        this.$set(this.model,"projectName",row.name);

      },

      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const parmas = {...this.model};
            this.$http
              .save("maintenance/repair-report", parmas)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      addLine() {
        this.model.tasks.push({
          key: this.$util.genKey(),
          "id": 0,
          "tenantId": 0,
          "planId": 0,
          "projectId": 0,
          "elevatorId": 0,
          "cycle": "",
          "planDate": "",
          "planWorkHour": 0,
          "assigned": true,
          "assigneeId": 0,
          "assignedTime": "",
          "maintainOrderId": 0,
          "actualStartTime": "",
          "actualEndTime": "",
          "actualWorkHour": 0,
          "projectName": "",
          "elevatorCode": "",
          "assigneeName": "",
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.tag{
  font-size: 20px;
  margin: 5px 0;
  text-align: center;
  color: black;
  font-weight: bolder;
  border: none;
  background-color: white;
}
.content{
  margin: 5px 0 5px 15px;
}
</style>
