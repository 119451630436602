<template>
  <el-dialog
    v-el-drag-dialog
    :title="role.id?$l('role.edit','编辑角色'):$l('role.add','新增角色')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    class="role-edit-model"
    width="1200px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      :model="role"
      class="form"
      label-width="100px">
      <el-form-item :label="$l('role.name','角色名称')" prop="name" :rules="$rule.notNull">
        <el-input v-model="role.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <el-alert
      class="tip"
      title="编辑权限包含（新增和编辑）,每次只能修改一端的权限"
      :closable="false"
      style="margin-bottom: 10px"
      type="warning">
    </el-alert>
    <el-tabs v-model="tabsValue" @tab-click="handleClick">
      <el-tab-pane label="桌面端权限" name="first"></el-tab-pane>
      <el-tab-pane label="微信端权限" name="second"></el-tab-pane>
      <el-tab-pane label="物业端权限" name="third"></el-tab-pane>
    </el-tabs>
    <div v-loading="contentLoading" class="permissionContent">
      <div
        v-for="(item,index) in menus"
        :key="index">
        <div
          v-if="!item.other.isManage"
          class="permissionTitle"
          style="background-color: #E6E6E6">
          <h1 style="display: inline-block">
            <el-checkbox v-model="item.checked">
              {{item[$l("role.en", "label")]}}
              <el-tag style="margin: 0 5px">一级菜单</el-tag>
            </el-checkbox>
          </h1>
        </div>
        <span v-for="(itemChild,indexChild) in item.children" :key="indexChild">
          <span
            v-if="itemChild.other.authorityType==='button'&&!itemChild.other.isManage&&!item.other.isManage"
            :style="{width:itemChild.children?'100%':'100px',paddingLeft: '15px',marginBottom: '8px',backgroundColor:''}">
            <el-checkbox v-model="itemChild.checked">{{$i18n.locale === "zh-CN" ? itemChild.label : itemChild.label}}<el-tag
              style="margin: 0 5px">{{itemChild.other.authorityTypeDesc}}</el-tag></el-checkbox>
          </span>
          <h2
            v-if="itemChild.other.authorityType!=='button'&&!itemChild.other.isManage&&!item.other.isManage"
            :style="{width:'100%',paddingLeft: '15px',marginBottom: '8px',backgroundColor:'#F5F5F5'}">
            <el-checkbox v-model="itemChild.checked">{{$i18n.locale === "zh-CN" ? itemChild.label : itemChild.label}}<el-tag
              style="margin: 0 5px">二级{{itemChild.other.authorityTypeDesc}}</el-tag></el-checkbox>

          </h2>
          <span v-if="itemChild.children&&!item.other.isManage&&!itemChild.other.isManage">
            <span
              v-for="(itemChildren,indexChildren) in itemChild.children"
              :key="indexChildren"
              :label="itemChildren.label"
              :style="{width:$l('role.permissionWidth','100px')}"
            >
              <span v-if="itemChildren.other.authorityType==='button'&&!itemChildren.other.isManage&&!item.other.isManage&&!itemChild.other.isManage" style="margin-left: 15px">
                <el-checkbox v-model="itemChildren.checked">
                  {{$i18n.locale === "zh-CN" ? itemChildren.label : itemChildren.label}}
                  <el-tag
                    style="margin: 0 5px">{{itemChildren.other.authorityTypeDesc}}
                  </el-tag>
                </el-checkbox>

              </span>

              <h3
                v-if="itemChildren.other.authorityType!=='button'&&!itemChildren.other.isManage"
                :style="{width:'100%',paddingLeft: '15px',marginBottom: '8px',backgroundColor:'rgba(245,245,245,0.99)'}">
                <el-checkbox v-model="itemChildren.checked">
                  {{$i18n.locale === "zh-CN" ? itemChildren.label : itemChildren.label}}<el-tag
                    style="margin: 0 5px">三级{{itemChildren.other.authorityTypeDesc}}</el-tag></el-checkbox>

              </h3>
              <span v-if="itemChildren.children&&!item.other.isManage">
                <span
                  v-for="(lastNode,i) in itemChildren.children"
                  :key="i"
                  :label="itemChildren.label"
                  :style="{width:$l('role.permissionWidth','100px')}"
                  style="margin-left: 15px;margin-bottom: 8px">
                  <el-checkbox v-model="lastNode.checked">
                    {{$i18n.locale === "zh-CN" ? lastNode.label : lastNode.label}}<el-tag
                      style="margin: 0 5px">{{lastNode.other.authorityTypeDesc}}</el-tag>
                  </el-checkbox>
                </span>

              </span>

            </span>
          </span>

        </span>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
    <pc-permissions-edit ref="PcPermissionsEdit" @save-success="getMenu"></pc-permissions-edit>
  </el-dialog>
</template>

<script>
  import PcPermissionsEdit from "@/views/basic/PcPermissionsEdit";
  export default {
    components: {
      PcPermissionsEdit,
    },
    data() {
      return {
        dialogVisible: false,
        contentLoading: true,
        submitLoading: false,
        tabsValue:"first",
        permissions: [],
        checkedPermissionIds: [],
        menus:[],
        role: {
          id: 0,
          name: "",
          permissions: [],
        },
        defaultProps: {
          children: "children",
          label: "name",
        },
      };
    },
    watch:{
      menus:{
        handler(prams) {
          console.log(prams,101);
        },
        deep:true,
      },
    },
    mounted() {
      this.getMenu();
    },
    methods: {
      handleClick(tab) {
        if (tab.name==="first") {
          this.getMenu("maintenance_company_backend");
        }else if (tab.name==="second") {
          this.getMenu("maintenance_company_app");
        }else if (tab.name==="third") {
          this.getMenu("customer_company_app");
        }
      },
      open(id) {
        this.role.id = id;
        this.dialogVisible = true;
        // this.getData();
        this.tabsValue="first";
        console.log(1755555);
        this.getMenu("maintenance_company_backend");

      },
      getMenu(systemType) {
        this.systemType = systemType;
        this.$http.get("/basic/authority/tree", {
          systemType,
        }).then(res => {
          this.menus = res;
          this.getData();
        });
      },
      getChecked(v,data) {
        if (v.id===data.id) {
          return true;
        }else if (data.children) {
          let locked =false;
          for (let i = 0; i < data.children.length; i++) {
            if (this.getChecked(v,data.children[i])) {
              locked=true;
            }
          }
          return  locked;
        }else {
          return false;
        }
      },
      formatMenus(item,data) {
        return item.map(v=>{
          for (let i = 0; i < data.length; i++) {
            if (this.getChecked(v,data[i])) {
              v.checked=true;
            }
            if (v.children) {
              v.children= this.formatMenus(v.children,data);
            }
          }
          return v;
        });
      },
      async getData() {
        try {
          // this.permissions = await this.$http.get("basic/authority/tree");
          if (this.role.id) {
            this.role = await this.$http.get(`authentication/role/${this.role.id}`);
            this.contentLoading = true;
            if (this.systemType==="maintenance_company_backend") {
              this.menus = this.formatMenus(this.menus,this.role.maintenanceCompanyBackendAuthorities);
            }else if (this.systemType==="maintenance_company_app") {
              this.menus = this.formatMenus(this.menus,this.role.maintenanceCompanyAppAuthorities);
            }else if (this.systemType==="customer_company_app") {
              this.menus = this.formatMenus(this.menus,this.role.customerCompanyAppAuthorities);
            }

            console.log(this.menus,210);
            this.contentLoading = false;

          }
          this.contentLoading = false;
        } catch (e) {
          this.contentLoading = false;
        }
      },
      handleSubmit() {
        this.$refs.form.validate((valid) => {
          console.log(this.menus,1777);
          if (valid) {
            this.submitLoading = true;
            console.log(this.role,223);
            if (this.systemType==="maintenance_company_backend") {
              this.role.maintenanceCompanyBackendAuthorities = this.menus;
              // this.role.customerCompanyAppAuthorities = [];
              // this.role.maintenanceCompanyBackendAuthorities = [];
            }else if (this.systemType==="maintenance_company_app") {
              this.role.maintenanceCompanyAppAuthorities = this.menus;
              // this.role.maintenanceCompanyBackendAuthorities = [];
              // this.role.customerCompanyAppAuthorities = [];
            }else if (this.systemType==="customer_company_app") {
              this.role.customerCompanyAppAuthorities = this.menus;
              // this.role.maintenanceCompanyBackendAuthorities = [];
              // this.role.maintenanceCompanyAppAuthorities = [];
            }
            if (!this.role.maintenanceCompanyBackendAuthorities) {
              this.role.maintenanceCompanyBackendAuthorities=[];
            }
            if (! this.role.maintenanceCompanyAppAuthorities) {
              this.role.maintenanceCompanyAppAuthorities =[];
            }
            if (!this.role.customerCompanyAppAuthorities) {
              this.role.customerCompanyAppAuthorities=[];
            }
            console.log(this.role);
            this.$http
              .save("authentication/role", this.role)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  @import "../../style/variables";

  .role-edit-model {
    width: 1920px;
    .permissionContent {
      border: 1px solid $border-color;
      border-radius: 2px;
    }

    .permissionTitle {
      font-size: 13px;
      padding: 9px 12px;
      background-color: #f5f5f5;
      margin-bottom: 10px;
    }

    .el-checkbox__label {
      font-size: 13px;
    }

  }
</style>
